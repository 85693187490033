import React from 'react';
import { Grid, Container, Card } from '@mui/material';
import CardItem from './CardItem';
import iniciofondo from '../imagenes/iniciofondo.jpg';
import a1 from '../imagenes/a1.jpg';
import a2 from '../imagenes/a2.jpg';
import revalidacion from '../imagenes/revalidacion.jpg';
import examinado from '../imagenes/examinado.jpg';
import medicos from '../imagenes/medicos.jpg';



const CardsContainers = () => {
  return (
    <Card sx={{ 
      
      
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
      margin: '0 auto',
      backgroundImage: `url(${iniciofondo})`,
      backgroundSize: 'cover',
      
    }}>
    <Container maxWidth="lg" sx={{ marginTop: '40px' ,}}>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6} md={4} lg={4} style={{ display: 'flex', justifyContent: 'center' }}>
          <CardItem 
            title='EXAMEN MEDICO - A1'
            descripcion='Requisitos para realizar el examen médico:

                        Edad mínima 18 años.
                        DNI original y vigente.
                        Celular cargado.

                        Evaluaciones que se realizan:

                        Grupo Sanguíneo
                        Psicología
                        Medicina General
                        Oftalmologia y Otorrino'
            image={a1} // Aquí añades la URL de la imagen
/>
          
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}style={{ display: 'flex', justifyContent: 'center' }}>
          <CardItem 
            title='EXAMEN MEDICO - AII'
            description='Requisitos para realizar el examen médico:

                        Edad mínima 18 años.
                        DNI original y vigente.
                        Celular cargado.

                        Evaluaciones que se realizan:

                        Grupo Sanguíneo
                        Psicología
                        Medicina General
                        Oftalmologia y Otorrino'
              image={a2}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}style={{ display: 'flex', justifyContent: 'center' }}>
          <CardItem 
            title='EXAMEN MEDICO - AIII'
            description='Requisitos para realizar el examen médico:

                        Edad mínima 18 años.
                        DNI original y vigente.
                        Celular cargado.

                        Evaluaciones que se realizan:

                        Grupo Sanguíneo
                        Psicología
                        Medicina General
                        Oftalmologia y Otorrino'
            image={revalidacion}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <CardItem 
            title='EXAMEN MEDICO - AIV'
            description='Requisitos para realizar el examen médico:

                        Edad mínima 18 años.
                        DNI original y vigente.
                        Celular cargado.

                        Evaluaciones que se realizan:

                        Grupo Sanguíneo
                        Psicología
                        Medicina General
                        Oftalmologia y Otorrino'
              image={medicos}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={6}style={{ display: 'flex', justifyContent: 'center' }}>
          <CardItem 
            title='EXAMEN MEDICO - AV'
            description='Requisitos para realizar el examen médico:

                        Edad mínima 18 años.
                        DNI original y vigente.
                        Celular cargado.

                        Evaluaciones que se realizan:

                        Grupo Sanguíneo
                        Psicología
                        Medicina General
                        Oftalmologia y Otorrino'
            image={examinado}
          />
        </Grid>
      </Grid>
    </Container>
    </Card>
  );
};

export default CardsContainers;
