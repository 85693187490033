// src/componentes/PhoneButton.js
import React from 'react';
import { Button } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const PhoneButton = ({ number }) => (
  <Button
    variant="contained"
    sx={{
      backgroundColor: '#25D366', // Color similar a WhatsApp
      color: 'white',
      borderRadius: '7%',
      '&:hover': {
        backgroundColor: '#128C7E', // Color al pasar el mouse
      },
      display: 'flex',
      alignItems: 'center',
    }}
    href={`https://wa.me/${number}`} // Cambiado para redirigir a WhatsApp
    target="_blank" // Abre en una nueva pestaña
    rel="noopener noreferrer" // Mejora la seguridad
  >
    <WhatsAppIcon sx={{ marginRight: 1 }} /> {/* Espacio entre el ícono y el texto */}
    {number}
  </Button>
);

export default PhoneButton;
