import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled } from '@mui/material/styles';

const Image = styled('img')({
  width: 'auto',
  height: 'auto',
  maxWidth: '100%',
  maxHeight: '90vh',
  display: 'block',
  margin: '0 auto',
});

const ModalContent = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  backgroundColor: 'transparent',
  outline: 'none',
});

const NavigationButton = styled(IconButton)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
});

const ImageModal = ({ open, handleClose }) => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("Modal open:", open); // Verificar si el modal está abierto
    if (open) {
      const fetchImages = async () => {
        try {
          console.log("Fetching images...");
          const response = await fetch('https://api.kalelsac.com/api/advertisement/list/');
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          console.log("Data fetched:", data); // Verificar los datos obtenidos

          if (data.length > 0) {
            setImages(data.map(item => item.archivo)); // Suponiendo que `archivo` es la URL de la imagen
          } else {
            setError('No se encontraron imágenes.');
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchImages();
    }
  }, [open]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  if (loading)

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: ModalContent,
        style: {
          width: 'auto',
          maxWidth: 'none',
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <IconButton
        edge="start"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
        }}
      >
        <CloseIcon />
      </IconButton>

      {images.length > 1 && (
        <>
          <NavigationButton
            onClick={handlePrevious}
            sx={{ left: 10 }}
            aria-label="previous"
          >
            <ArrowBackIcon />
          </NavigationButton>
          <NavigationButton
            onClick={handleNext}
            sx={{ right: 10 }}
            aria-label="next"
          >
            <ArrowForwardIcon />
          </NavigationButton>
        </>
      )}

      {images.length > 0 ? (
        <Image src={images[currentIndex]} alt={`Imagen ${currentIndex + 1}`} />
      ) : (
        <p>No image available</p>
      )}
    </Dialog>
  );
};

export default ImageModal;
