// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './componentes/header/Header';
import Footer from './componentes/Footer';
import Inicio from './paginas/Inicio';
import Nosotros from './paginas/Nosotros';
import Preguntas from './paginas/Preguntas';



import ImageModal from './componentes/ImageModal';
import inicioimagen from './imagenes/inicioimagen.jpg'; // Ruta correcta al logo
import A1 from './componentes/Servicios/A1';
import Revalidacion from './componentes/Servicios/Revalidacion';



const App = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    // Abre el modal cuando la página se carga por primera vez
    handleOpenModal();
  }, []); // El array vacío asegura que esto ocurra solo al montar el componente

  return (
    <Router>
      <Header onLogoClick={handleOpenModal} />
      <ImageModal open={isModalOpen} handleClose={handleCloseModal} logo={inicioimagen} />
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/preguntas" element={<Preguntas />} />
        <Route path="/a1" element={<A1 />} />
        <Route path="/revalidacion" element={<Revalidacion />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;