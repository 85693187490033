import React from 'react';

import { Box,  styled } from '@mui/material';
import InfoCards from '../InfoCards'; // Asegúrate de que esta ruta sea correcta
import moto3 from '../../imagenes/moto3.jpg';
import moto2 from '../../imagenes/moto2.jpg';
import moto4 from '../../imagenes/moto4.jpg';


const ImageContainer = styled(Box)({
  width: '100 %', // Ocupa todo el ancho
  height: 'auto', // Ajusta la altura automáticamente
  // Asegura que el contenido esté posicionado adecuadamente
  zIndex: 0, // Asegura que esté detrás de otros elementos en caso de overlap
  // Espacio debajo de la imagen
  padding:'0',
  position: 'relative',
});


// Estilos para el contenedor de tarjetas
const CardsContainer = styled(Box)({
  display: 'flex',

  alignItems: 'center',
  
  gap: '20px',
  marginTop: '20px',
  position: 'relative',
  zIndex: 1,
  padding: '20px', // Agregar padding para margen decente
});

// Estilos para el contenedor principal
const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0px',
  backgroundColor: '#f5f5f5',
  minHeight: 'calc(100vh - 64px)',
  boxSizing: 'border-box',
  position: 'relative',
  
});

const A1 = () => {
  return (
    <MainContainer>
    <ImageContainer>
      <img src={moto4} alt="Motoclista de inicio" style={{ width:'100%', height:'40%' }} />
  </ImageContainer>
    <CardsContainer>
      <InfoCards
        title="Misión"
        text="Brindar servicios de salud en sus diversas especialidades, contando para ello con el apoyo de la tecnología médica e infraestructura adecuada que garanticen la seguridad, efectividad y calidad de nuestras atenciones."
        color="#2196f3"
        image={moto2} // Puedes usar tu propia imagen
      />
      <InfoCards
        title="Visión"
        text="Consolidarnos como una institución líder en la prestación de servicios médicos, en sus diversas especialidades; orientados siempre a garantizar una satisfacción que va más allá de las expectativas de nuestros clientes."
        color="#9e9e9e"
        image={moto3} // Puedes usar tu propia imagen
      />
    </CardsContainer>
    
    
  </MainContainer>
);
};

export default A1;