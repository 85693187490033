import React from 'react';
import MenuAppBar from './MenuAppBar';




const Header = ({ onLogoClick }) => (
  <MenuAppBar onLogoClick={onLogoClick} />
);

export default Header;
