import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const StyledCard = styled(Card)({
  width: '100%', // Ancho completo del contenedor
  height: '600px', // Altura fija para todas las tarjetas
  display: 'flex',
  flexDirection: 'column',
  transition: '0.3s', // Transición suave para el efecto de hover
  '&:hover': {
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Sombra al pasar el mouse
    transform: 'scale(1.02)', // Opción para un ligero aumento de tamaño
  },
});

const StyledImage = styled('img')({
  width: '100%', // La imagen ocupa todo el ancho del contenedor
  height: '50%', // Altura de la imagen
  objectFit: 'cover', // Mantiene la proporción de la imagen
});

const CustomCard = ({ image, title, text }) => {
  return (
    <StyledCard>
      <StyledImage src={image} alt={title} />
      <CardContent style={{ flexGrow: 1 }}> {/* Permite que el contenido ocupe el espacio restante */}
        <Typography
          variant="h5"
          sx={{
            marginBottom: '12px',
            fontFamily: 'PT Sans, sans-serif',
            fontWeight: '900',
            color: '#2E4E6C',
            fontSize: '28px',
          }}
          align="center"
        >
          {title}
        </Typography>
        <Typography variant="body2" color="black" align="center" fontSize='15px'>
          {text}
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

export default CustomCard;
