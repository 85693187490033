import React from 'react';
import { Box, Grid, styled } from '@mui/material';
import InfoCards from '../InfoCards'; // Asegúrate de que esta ruta sea correcta
import sangre from '../../imagenes/sangre.jpg';
import psicologia from '../../imagenes/psicologia.jpg';
import medicina from '../../imagenes/medicina.jpg';
import oftalmologia from '../../imagenes/oftalmologia.jpg';
import moto4 from '../../imagenes/moto4.jpg';

const ImageContainer = styled(Box)({
  width: '100%', // Ocupa todo el ancho
  height: '10%', // Ajusta la altura automáticamente
  zIndex: 0,
  padding: '0',
  position: 'relative',
});

const CardsContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap', // Permitir que las tarjetas se envuelvan
  justifyContent: 'center',
  gap: '20px',
  marginTop: '20px',
  position: 'relative',
  zIndex: 1,
  
});

const RequirementsContainer = styled(Box)({
  fontFamily: 'sans-serif',
  color: '2E4E6C#',
  fontSize: '25px',
  padding: '20px',
 // backgroundColor: '#fff', // Fondo blanco para destacar
  marginLeft:'80px',
  textAlign:'left'
 
});


const CardText = styled('p')({
  color: '#2E4E6C', // Color del texto en negro
  textAlign:'center',
  fontFamily: 'PT Sans, sans-serif',
});

const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  backgroundColor: '#f5f5f5',
  minHeight: 'calc(100vh - 64px)',
  boxSizing: 'border-box',
  position: 'relative',
});


const StyledInfoCard = styled(InfoCards)({
  width: '200px', // Ajusta el ancho según sea necesario
  height: '300px', // Ajusta la altura según sea necesario
  display: 'flex',
// Asegura que el contenido se distribuya uniformemente
});

const A1 = () => {
  return (
    <MainContainer>
    <ImageContainer>
      <img src={moto4} alt="Motoclista de inicio" style={{ width: '100%', height: '100%' }} />
    </ImageContainer>

    <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} justifyContent={'center'}>
          <RequirementsContainer>
          <CardText><h3>Requisitos para realizar el examen médico:</h3></CardText>
            <ol>
              <li>Edad mínima 18 años.</li>
              <li>DNI original y vigente.</li>
              <li>Celular cargado.</li>
            </ol>

            <CardText><h3>Evaluaciones que se realizan :</h3></CardText>
          </RequirementsContainer>
        </Grid>
        </Grid>

    <CardsContainer container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <StyledInfoCard
          title={<CardText>GRUPO SANGUÍNEO</CardText>}
          text={<CardText>Consiste en tomar una muestra de sangre para identificar grupo y factor sanguíneo. No temas, la muestra se obtiene del dedo por punción capilar y sólo son necesarias tres gotas de sangre.</CardText>}
          image={sangre}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <StyledInfoCard
          title={<CardText>PSICOLOGÍA</CardText>}
          text={<CardText>La evaluación psicológica es la más extensa del examen médico. Consta de los siguientes test: Test de Retención Visual de Benton, Test De Dominós (prueba de inteligencia), Inventario de Personalidad de Eysenck (B) (prueba de personalidad), Test De Machover (consiste en dibujar una figura humana), Test De Audit (test que permite identificar los trastornos por consumo de alcohol) y Test de la Persona con Arma (consiste en dibujar una persona con Arma.</CardText>}
          image={psicologia}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <StyledInfoCard
          title={<CardText>MEDICINA GENERAL</CardText>}
          text={<CardText>Te tomarán la presión, pasarás una prueba de equilibrio, realizarás una prueba de fuerza muscular donde te pedirán cargar unas pesas de 5 kg sobre la altura de tu cabeza y el médico te realizará una breve entrevista.</CardText>}
          image={medicina}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <StyledInfoCard
          title={<CardText>OFTALMOLOGIA / OTORRINO</CardText>}
          text={<CardText>Consiste en tomar una muestra de sangre para identificar grupo y factor sanguíneo. No temas, la muestra se obtiene del dedo por punción capilar y sólo son necesarias tres gotas de sangre.</CardText>}
          image={oftalmologia}
        />
      </Grid>
    </CardsContainer>
  </MainContainer>
);
};

export default A1;
