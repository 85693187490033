import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import DirectionsIcon from '@mui/icons-material/Directions';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { styled } from '@mui/material/styles';

// Estilos personalizados para la tarjeta
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 400,
  alignItems:'center',
  margin: theme.spacing(2),
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[6],
    backgroundColor: theme.palette.primary.light,
  },
}));

// Estilos para la imagen
const StyledCardMedia = styled(CardMedia)({
  height: 250,
  objectFit: 'cover',
});

// Componente LocationCard que recibe props para ser reutilizable
const LocationCard = ({ image, title, description, hours, phone, whatsapp, locationUrl, wazeUrl }) => {
  return (
    <StyledCard>
      <CardMedia
        component="img"
        image={image}
        alt={title}
        sx={{
          height: 250,
          objectFit: 'cover',
        }}
      />
      <CardContent>
        <Typography variant="h5" component="div" align="center" gutterBottom>
          {title}
        </Typography>
        <Box mt={2} mb={2}>
          <Typography variant="body2" color="text.secondary" align="center" paragraph>
            {description}
          </Typography>

          {/* Sección de horarios con icono de reloj */}
          {hours && (
            <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
              <AccessTimeIcon sx={{ mr: 1 }} />
              <Typography variant="body2" color="text.secondary">
                {hours}
              </Typography>
            </Box>
          )}

          {/* Sección de números de teléfono con iconos */}
          {phone && (
            <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
              <PhoneIcon sx={{ mr: 1 }} />
              <Typography variant="body2" color="text.secondary">
                {phone}
              </Typography>
            </Box>
          )}
          {whatsapp && (
            <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
              <WhatsAppIcon sx={{ mr: 1 }} />
              <Typography variant="body2" color="text.secondary">
                {whatsapp}
              </Typography>
            </Box>
          )}
        </Box>

        {/* Botones para abrir Google Maps y Waze */}
        <Box display="flex" justifyContent="center" gap={2} mt={2}>
          <Button
            variant="contained"
            color="primary"
            href={locationUrl}
            target="_blank"
            startIcon={<GoogleIcon />}
          >
            Maps
          </Button>
          <Button
            variant="contained"
            color="secondary"
            href={wazeUrl}
            target="_blank"
            startIcon={<DirectionsIcon />}
          >
            Waze
          </Button>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default LocationCard;
