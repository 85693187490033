import React from 'react';
import { Card, CardContent, CardActions, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '5%',  // Borde redondeado al 5%
  boxShadow: theme.shadows[3],  // Sombreado
  transition: 'transform 0.3s, box-shadow 0.3s',  // Efecto hover
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[6],
  },
}));

const TitleContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#1976d2',  // Fondo azul
  padding: theme.spacing(2),
  borderTopLeftRadius: '5%',
  borderTopRightRadius: '5%',
}));

const StyledImage = styled('img')({
  width: '100%',       // Ancho completo
  height: 'auto',      // Altura automática para mantener la relación de aspecto
  borderRadius: '5%',  // Borde redondeado al 5%
  marginTop: '0px',
  marginBottom: '5px',   // Margen inferior
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
});

const CardItem = ({ title, description, image }) => {
  return (
    <StyledCard sx={{ maxWidth: 345, margin: '20px' }}>
      <TitleContainer>
        <Typography variant="h5" component="div" color="white" justifyContent="center" textAlign="center" sx={{ fontFamily: 'Asap Condensed, sans-serif' }}>
          {title}
        </Typography>
      </TitleContainer>
      
      <CardContent>
        {image && <StyledImage src={image} alt={title} />}
        <div>
          <strong>Requisitos para realizar el examen médico:</strong>
          <ul>
            <li>Edad mínima 18 años</li>
            <li>DNI original y vigente.</li>
          </ul>
          <strong>Evaluaciones que se realizan:</strong>
          <ul>
            <li>Grupo Sanguíneo</li>
            <li>Psicología</li>
            <li>Vista y Oído</li>
            <li>Medicina General</li>
          </ul>
        </div>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button 
            size="small" 
            component={Link} 
            to="/servicios" 
            variant="contained" 
            color="secondary"
          >
            Leer más
          </Button>
        </Box>
      </CardActions>
    </StyledCard>
  );
};

export default CardItem;
