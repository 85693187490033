import React from 'react';
import Slider from '../componentes/Slider';
import CardsContainers from '../componentes/CardsContainers';
import '../estilos/Inicio.css';
import LocationCard from '../componentes/LocationCard';
import empresa from '../imagenes/empresa.jpg';
import { Box, Grid } from '@mui/material';

const Inicio = () => {
  return (
    <div style={{ width: '100%', margin: 0 }}>
      <Slider />
      <CardsContainers />
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <LocationCard 
                image={empresa}
                title="INDEPENDENCIA"
                description="Av. Contisuyo 348, Urb. Tahuantinsuyo (a ½ cuadra Estación Naranjal del Metropolitano)"
                hours="Lun a Vie de 8:00 am a 7:00 pm | Sábado de 8:00 am a 2:00 pm"
                phone="(01) 551 3215"
                whatsapp="970 827 085"
                locationUrl="https://www.google.com/maps/place/YourLocation"
                wazeUrl="https://waze.com/ul?ll=YourLocation"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345099675!2d144.95373531531632!3d-37.81720997975169!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f2b0f2f%3A0x5045675218ceed0!2sYourLocation!5e0!3m2!1sen!2sus!4v1616161616161!5m2!1sen!2sus" 
                width="100%" 
                height="550" 
                style={{ border: 0 }} 
                allowFullScreen 
                loading="lazy"
                title="Mapa de ubicación"
              ></iframe>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Inicio;
