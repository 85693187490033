import React, { useState } from 'react';
import { AppBar, Box, Container, Toolbar, Grid } from '@mui/material';
import Logo from './Logo';
import DesktopMenu from './DesktopMenu';
import PhoneButton from '../header/PhoneButton'; // Asegúrate de que la ruta sea correcta
import A1 from '../Servicios/A1';

const MenuAppBar = ({ onLogoClick }) => {
  const [selectedComponent, setSelectedComponent] = useState(null);

  const handleMenuClick = (component) => {
    setSelectedComponent(component);
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', color: '#333' }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ minHeight: '120px' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Logo onClick={onLogoClick} />
            </Box>
            <DesktopMenu onMenuClick={handleMenuClick} />
            
            {/* Sección de botones */}
            <Box sx={{ display: { xs: 'flex', md: 'block' }, flexDirection: 'column', alignItems: 'flex-end' }}>
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <PhoneButton number="925984699" />
                </Grid>
                <Grid item>
                  <PhoneButton number="925984699" />
                </Grid>
              </Grid>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Renderiza el componente seleccionado */}
      {selectedComponent === 'servicios' && <A1 />}
    </>
  );
};

export default MenuAppBar;
