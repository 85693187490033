import React from 'react';
import { Card, CardContent, CardMedia, Typography, CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';

// Estilos personalizados para las tarjetas
const StyledCard = styled(Card)(({ theme, color }) => ({
  maxWidth: '300px',  // Cambiar a maxWidth
  width: '100%', // Permitir que ocupe el 100% del ancho disponible
  height: '500px', // Establecer una altura fija
  margin: theme.spacing(2), // Espaciado alrededor de la tarjeta
  backgroundColor: color || '#fff',
  color: '#fff',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[6],
  },
}));

const InfoCard = ({ title, text, color, image }) => {
  return (
    <StyledCard color={color}>
      <CardActionArea>
        {image && (
          <CardMedia
            component="img"
            height="140" // Ajusta la altura de la imagen si es necesario
            image={image}
            alt={title}
          />
        )}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" color="blue">
            {title}
          </Typography>
          <Typography variant="body2" color="#fff">
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
};

export default InfoCard;
