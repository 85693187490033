// src/components/Slider.js
import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'; // Importa los estilos de la librería

import img1 from '../imagenes/slider01.jpg';  // Asegúrate de que la ruta sea correcta
import img2 from '../imagenes/slider02.jpg'; // Asegúrate de que la ruta sea correcta
import '../estilos/Slider.css'; 

const images = [
  {
    original: img1,
    thumbnail: img1,
    originalWidth: 1000,
    originalHeight: 600,
    thumbnailWidth: 250,
    thumbnailHeight: 150
  },
  {
    original: img2,
    thumbnail: img2,
    originalWidth: 1000,
    originalHeight: 600,
    thumbnailWidth: 250,
    thumbnailHeight: 150
  },
];

const Slider = () => {
  return (
    <div className='slider-container'>
      <ImageGallery 
        items={images} 
        autoPlay={true}
        showThumbnails={false}
        infinite={true}
        slideInterval={7000}      // Ajusta el tiempo entre slides (en milisegundos)
        showPlayButton={false}     // Oculta el botón de reproducción
        showFullscreenButton={false}
        showNav={false}
      />
    </div>
  );
};

export default Slider;
