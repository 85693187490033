import React from 'react';
import { Box, styled, Grid } from '@mui/material';
import volante from '../imagenes/volante.jpg';
import medicos from '../imagenes/medicos.jpg';
import LocationCard from '../componentes/LocationCard';
import empresa from '../imagenes/empresa.jpg';
import nosotrosfoto from '../imagenes/nosotrosfoto.jpg';
import CustomCard from '../componentes/CustomCard';

const ImageContainer = styled(Box)({
  width: '100%',
  height: '40%',
  zIndex: 0,
  padding: '0',
  position: 'relative',
});

const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: "center",
  padding: '0px',
  backgroundColor: '#f5f5f5',
  minHeight: 'calc(100vh - 64px)',
  boxSizing: 'border-box',
  position: 'relative',
});



const CardContainer = styled(Grid)({
  marginTop: '20px', // Espaciado entre la imagen y las tarjetas
  padding: '0 52px',
 // Espacio horizontal (izquierda y derecha)
 justifyContent:"space-between"
});

const Nosotros = () => {
  return (
    <MainContainer>
      <ImageContainer>
        <img src={nosotrosfoto} alt="Imagen de nosotros" style={{ width: '100%', height: '40%' }} />
      </ImageContainer>
      
      
      <CardContainer container spacing={2} >
        <Grid item xs={12} sm={6} lg={6}>
          <CustomCard 
            image={volante} 
            title="MISIÓN" 
            text="Consolidarnos como una empresa líder en el sector que nos desenvolvemos a nivel Regional
                  y del país aportando a la sociedad un servicio que garantice seguridad de cada uno de nuestros clientes."
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <CustomCard 
            image={medicos} 
            title="VISIÓN" 
            text="Como empresa especializada en el rubro de salud y evaluación médica para la obtención de licencias de conducir estamos 
                  comprometidos en brindar una atención médica y de apoyo al diagnóstico altamente especializada, con gran calidad, calidez y profesionalismo; 
                  contando para ello con tecnología de punta, que nos permite otorgar un servicio de calidad a todos nuestros clientes."
          />
        </Grid>
      </CardContainer>

      <LocationCard
        image={empresa}
        title="INDEPENDENCIA"
        description="Av. Contisuyo 348, Urb. Tahuantinsuyo (a ½ cuadra Estación Naranjal del Metropolitano)"
        hours="Lun a Vie de 8:00 am a 7:00 pm | Sábado de 8:00 am a 2:00 pm"
        phone="(01) 551 3215"
        whatsapp="970 827 085"
        locationUrl="https://www.google.com/maps/place/YourLocation"
        wazeUrl="https://waze.com/ul?ll=YourLocation"
      />
    </MainContainer>
  );
};

export default Nosotros;
