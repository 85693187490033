import React from 'react';
import { Box, Container, Typography, Link, Grid, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import '../estilos/Footer.css';

const Footer = () => {
  return (
    <Box component="footer" py={3} mt={5} className="footer-bg" color="white">
      <Container className="footer-container">
        <Grid container spacing={4} justifyContent="center">
          {/* Enlaces relacionados */}
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>
                Enlaces relacionados
              </Typography>
              <ul>
                <li><Link href="#" color="inherit">Simulacro Examen Reglas</Link></li>
                <li><Link href="#" color="inherit">Programar Examen (Touring)</Link></li>
                <li><Link href="#" color="inherit">Trámite de Licencia</Link></li>
                <li><Link href="#" color="inherit">Sistema de Puntos</Link></li>
                <li><Link href="#" color="inherit">Cursos Reducir Puntos</Link></li>
              </ul>
            </Box>
          </Grid>

          {/* Contacto */}
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h6" gutterBottom>
                Contacto
              </Typography>
              <ul>
                <li>
                  <Link href="https://wa.me/1234567890" color="inherit" target="_blank" rel="noopener noreferrer">
                    <WhatsAppIcon /> +123 456 7890
                  </Link>
                </li>
                <li>
                  <Link href="https://wa.me/0987654321" color="inherit" target="_blank" rel="noopener noreferrer">
                    <WhatsAppIcon /> +098 765 4321
                  </Link>
                </li>
              </ul>
            </Box>
          </Grid>

          {/* Síguenos */}
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h6" gutterBottom>
                Síguenos
              </Typography>
              <Box display="flex" justifyContent="center">
                <IconButton component={Link} href="https://facebook.com" color="inherit" target="_blank" rel="noopener noreferrer">
                  <FacebookIcon />
                </IconButton>
                <IconButton component={Link} href="https://twitter.com" color="inherit" target="_blank" rel="noopener noreferrer">
                  <TwitterIcon />
                </IconButton>
                <IconButton component={Link} href="https://instagram.com" color="inherit" target="_blank" rel="noopener noreferrer">
                  <InstagramIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Typography variant="body2" align="center" color="textSecondary" mt={4}>
          &copy; 2024 Kalel.Sac. Todos los derechos reservados.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
