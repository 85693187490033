import React from 'react';
import Logokalel from '../../imagenes/logo.jpg';
const Logo = ({ onClick }) => (
  <img
    src={Logokalel}
    alt="Logokalel"
    onClick={onClick}
    style={{ height: 70, cursor: 'pointer', justifyContent :'center'}}
  />
);

export default Logo;
